:root {
  --select-focus: #b40089;
  --select-color: white;
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  overflow: hidden;
  padding-right: 2rem;
  text-overflow: ellipsis;
  max-width: 40ch;
}

.select select::-ms-expand {
  display: none;
}

.select {
  display: grid;
  grid-template-areas: 'select';
}

.select select,
.select::after {
  grid-area: select;
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}
