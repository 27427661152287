.tracks-cell {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
}

.open-cell {
  width: 100%;
  text-align: center;
}

.text-header-cell {
  text-align: left;
}

.track-table-cell {
  padding: 4px;
  box-sizing: border-box;
}

.track-new-indicator {
  width: 100%;
  border-radius: 2px;
  background-color: white;
  height: 100%;
  -webkit-box-shadow:
    inset 0 1px 1px rgb(255, 255, 255),
    0 0 8px rgb(255, 255, 255);
  box-shadow: 0 0 8px 0 white;
}

.following {
  font-weight: 600;
}

.cart-popup-input {
  margin: 2px !important;
  padding: 6px !important;
}

.score-table {
  width: 100%;
}

.table-cell-button {
  flex: 1;
  vertical-align: middle;
  margin: 0;
}

.table-button-cell-header {
  text-align: center;
}

.table-cell-button {
  opacity: 0.75;
}

.table-cell-button:disabled {
  opacity: 0.25;
}

.table-cell-button:hover:not(:disabled) {
  opacity: 1;
}

/*.tracks-table tbody tr:hover .table-cell-button:not(:disabled) {*/
/*  opacity: 1;*/
/*}*/

.cart-bar {
  box-sizing: border-box;
  position: relative;
  flex: 1;
  display: inline-flex;
  align-items: center;
}

.tracks-table {
  border-collapse: collapse;
  font-size: 75%;
  width: 100%;
}

.tracks-table > tbody::-webkit-scrollbar {
  display: none;
}

.tracks-table > tbody {
  -ms-overflow-style: none;
}

.tracks-table > tbody > tr.playing {
  background-color: #5181bd;
  color: white;
}

.tracks-table > tbody > tr {
  background-color: #666;
  margin: 0 0 2px 0;
  color: white;
  line-height: 2rem;
  font-weight: 350;
  cursor: pointer;
}

.tracks-table > thead > tr {
  background-color: var(--page-container-background-color);
  color: white;
  line-height: 2rem;
  font-size: 100%;
}

.tracks-table > thead > th {
  color: white;
  border: 1px;
  text-align: left;
  font-weight: normal;
  text-transform: uppercase;
}

.tracks-table > tfoot > tr {
  border-top: 1px solid black;
}

.tracks-table .track:last-child {
  margin: 0;
}

.tracks-table-header {
  display: block;
}

.tracks-top_bar_group {
  display: flex;
  padding: 4px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}

.refresh-tracks {
  margin-left: auto;
}

.filter-active {
  background-color: #b40089;
  padding: 5px 10px;
  border-radius: 5px;
}

.top-bar {
  background-color: #222;
}

.open-share-cell {
  display: inline-flex;
}

.score-popup_content {
  flex-direction: column;
  align-items: center;
}

@media (hover: hover) and (pointer: fine) {
  .tracks-table > tbody > tr:hover {
    background-color: #767676;
  }

  .tracks-table > tbody > tr.playing:hover {
    background-color: #639be7;
  }
}

.follow-ignore-cart-cell {
  display: inline-flex;
}

@media (max-width: 768px) {
  .table-cell-button-row > .table-cell-button:not(:first-child),
  .table-cell-button-row__last {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #808080;
  }

  .table-cell-button-row > .table-cell-button:not(:last-child),
  .table-cell-button-row__first {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .table-cell-button-row > .table-cell-button {
    margin: 0;
  }

  .table-cell-button {
    width: 100%;
    line-height: 1.5rem;
  }

  .refresh-tracks {
    order: 3;
  }

  .cart-bar {
    order: 3;
  }

  .tracks-top_bar_group {
    margin: 0;
    min-width: calc(100% - 4px);
  }

  .tracks-table-header-columns {
    display: none;
  }

  .new-cell {
    width: 4px;
    margin: 6px;
  }

  .follow-ignore-cart-cell {
    max-width: 90px;
  }

  .track-mark-heard-button {
    display: none;
  }

  .track-table-cell {
    display: block;
    overflow: hidden;
  }

  .track-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .track-details-left {
    display: flex;
    flex-direction: column;
  }

  .track-details-content {
    line-height: 1.5em;
  }

  .artist-cell {
    font-size: 130%;
    font-weight: 300;
  }

  .title-cell {
    font-size: 130%;
  }

  .label-cell {
    font-size: 130%;
  }

  .released-cell:before {
    content: 'Released: ';
  }

  .added-cell:before {
    content: 'Added: ';
  }

  .published-cell:before {
    content: 'Published: ';
  }

  .dates-cell > *:after {
    content: ', ';
  }

  .dates-cell > *:last-child:after {
    content: '';
  }

  .released-cell,
  .published-cell,
  .added-cell {
    font-size: 80%;
    opacity: 0.8;
    display: block;
    padding: 0 4px;
  }

  .key-cell::before {
    content: 'Key: ';
    white-space: pre;
  }

  .bpm-cell::before {
    content: 'BPM: ';
    white-space: pre;
  }

  .genre-cell::before {
    content: 'Genres: ';
    white-space: pre;
  }

  .score-cell button::before {
    content: 'Score: ';
    white-space: pre;
    filter: invert(100%);
    vertical-align: middle;
  }

  .open-cell {
    max-width: 150px;
  }

  .follow-button-label::before {
    content: 'Follow...';
  }

  .ignore-button-label::before {
    content: 'Ignore...';
  }

  .follow-ignore-cart-cell {
    width: 120px;
    flex-direction: column;
  }

  .cart-cell-content {
    width: 100%;
    text-align: center;
  }

  .follow-button-label,
  .ignore-button-label,
  .cart-button-label {
    display: inline-block;
    width: 100%;
  }

  .email-not-verified-info {
    font-size: 60%;
    order: 5;
    width: 15em;
    display: inline-block;
    white-space: pre-wrap;
  }

  .share-button-container {
    margin-top: 4px;
  }

  .track-table-cell .pill-link + .pill-link,
  .track-table-cell .pill-button + .pill-link,
  .track-table-cell .pill-link + .pill-button {
    margin-top: 4px;
  }
}

@media (min-width: 591px) {
  .score-popup_content {
    width: 250px;
    margin-top: -4px;
  }
}

@media (min-width: 769px) and (max-width: 1279px) {
  .track-details-left {
    width: 85%;
  }

  .track-details-center {
    width: 15%;
  }

  .track-details-right {
    display: none;
  }
}

@media (min-width: 769px) {
  .refresh-tracks {
    order: 3;
  }

  .cart-bar {
    order: 2;
  }

  .top-bar {
    border-bottom: 1px solid black;
  }

  .tracks-table-header-columns {
    display: flex;
    border-bottom: 1px solid black;
  }

  .table-cell-button-row > .table-cell-button:not(:first-child),
  .table-cell-button-row__last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #808080;
  }

  .table-cell-button-row > .table-cell-button:not(:last-child),
  .table-cell-button-row__first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .table-cell-button-row > .table-cell-button {
    margin: 0;
  }

  .new-cell {
    float: left;
    width: 3rem;
    text-align: center;
  }

  .track-mark-heard-button {
    height: 100%;
  }

  .new-cell-content {
    display: block;
    width: 100%;
    text-align: center;
  }

  .track-new-indicator {
    display: none;
  }

  .track-table-cell {
    display: block;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .track-details {
    /* 3rem = new-cell width, 183px = space reserved for open-share-cell, 60px = space reserved for score, 64px = cart cell 8px = some margin, padding, \_o_/ */
    width: calc(100% - 208px - 60px - 64px - 3rem - 8px);
  }

  .track-details-content {
    float: left;
  }

  .artist-cell {
    width: 32%;
  }

  .title-cell {
    width: 40%;
  }

  .label-cell {
    width: 28%;
  }

  .dates-cell {
    width: 30%;
  }

  .released-cell,
  .added-cell {
    width: 100%;
  }

  .key-cell,
  .bpm-cell {
    width: 25%;
  }

  .genre-cell {
    width: 50%;
  }

  .key-cell,
  .bpm-cell,
  .genre-cell {
    display: inline;
    text-align: center;
  }

  .score-cell {
    width: 60px;
    text-align: center;
  }

  .cart-cell {
    width: 64px;
  }

  .follow-ignore-cart-cell {
    width: 128px;
    gap: 4px;
  }

  .open-share-cell {
    width: 208px;
  }

  .ignore-button-icon {
    display: none;
  }

  .cart-button-label {
    display: none;
  }

  .open-cell {
    overflow: visible;
  }

  .share-cell {
    width: 30%;
    text-align: center;
  }

  .share-button-container {
    display: inline-block;
    position: relative;
    margin-left: 4px;
  }

  .track-table-cell .pill-link + .pill-link,
  .track-table-cell .pill-button + .pill-link {
    margin-left: 2px;
  }

  .track-table-cell .pill-link + .pill-button {
    margin-left: 4px;
  }
}

@media (min-width: 1280px) {
  .track-details-left {
    width: 60%;
  }

  .track-details-center {
    width: 10%;
  }

  .track-details-right {
    width: 30%;
  }
}
