@import './Login.css';
@import './PageContainer.css';
@import './TextInput.css';
@import './SelectButton.css';
@import './CartSelect.css';
@import './Popup.css';

html {
  --page-container-background-color: #333;
  --top-bar-background-color: #222;
  height: 100dvh;
  overflow: hidden;
}

body {
  width: 100%;
  min-height: 100dvh;
  font-family: 'Lato', sans-serif;
  background-color: black;
}

p {
  font-size: 85%;
  max-width: 50rem;
}

a,
a:visited,
a:hover,
a:active {
  color: white;
}

.app {
  margin-top: 80px;
}

.ignored {
  text-decoration: line-through;
}

a.link {
  color: white;
}

a.link:visited {
  color: white;
  opacity: 0.8;
}

/* button and input resets */
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  color: inherit;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button {
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

audio {
  height: 31px;
}

.align-center-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-center-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.full-screen-popup-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 2;
}

.full-screen-popup {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  background-color: #ccc;
  box-shadow: rgba(0, 0, 0, 0.27) 2px 2px 40px 0px;
  color: black;
  position: relative;
  box-sizing: border-box;
}

.full-screen-popup a:not(.button),
.full-screen-popup a:not(.button):visited,
.full-screen-popup a:not(.button):hover,
.full-screen-popup a:not(.button):active {
  color: inherit;
}

.full-screen-popup-title {
  margin-top: 0;
  text-align: center;
}

.full-screen-popup-close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 30px;
}

.no-style-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.no-style-link {
  text-decoration: none;
  display: inline-block;
}

.comma-list {
  padding-left: 0;
  display: inline;
  list-style: none;
}

.comma-list li {
  display: inline;
}

.comma-list li:after {
  content: ', ';
}

.comma-list li:last-child:after {
  content: '';
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0005;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.scroll-container {
  overflow-y: auto;
}

.followed {
  font-weight: bold;
}

.input-layout {
  display: flex;
  box-sizing: border-box;
}

.input-layout > * {
  margin-left: 4px;
}

.input-layout > *:first-child {
  margin-left: 0;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 10rem;
  height: 1rem;
  margin: 5px;
  background: #222;
  border-radius: 0.25rem;
  background-image: linear-gradient(rgb(180, 0, 137), rgb(180, 0, 137));
  background-repeat: no-repeat;
  transition:
    box-shadow 0.25s ease-in-out,
    width 0.25s ease-in-out;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
  background: white;
  cursor: ew-resize;
  transition: background 0.3s ease-in-out;
  box-shadow: 0 0 2px 0 #555;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.react-joyride__tooltip button:disabled {
  background-color: transparent !important;
  color: #333333 !important;
}

*::-webkit-scrollbar {
  display: none;
}

.cart-details {
  font-size: 0.7rem;
  color: #888;
  padding: 4px;
}

@media (max-width: 590px) {
}

@media (min-width: 591px) {
}

@media (max-width: 768px) {
  .full-screen-popup {
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: 2rem;
    right: 2rem;
    max-height: calc(100dvh - 2rem);
    position: absolute;
  }

  .full-screen-popup-container {
    width: 100%;
    height: 100%;
  }

  .input-layout {
    width: 100%;
  }

  .link-icon {
    font-size: 120%;
    margin: 2px;
  }
}

@media (min-width: 769px) {
  .full-screen-popup {
    max-width: 60%;
    max-height: calc(100dvh - 4rem);
  }

  .full-screen-popup-container {
    width: 100%;
    height: 100%;
  }

  .cart-filter {
    max-width: 20rem;
  }
}

@media (min-width: 769px) and (max-width: 1279px) {
}

@media (max-width: 1279px) {
}

@media (min-width: 1280px) {
}
