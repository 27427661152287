/* button and input resets */
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  color: inherit;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button {
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  padding-block: 0;
}

.button-push_button {
  border: 1px solid;
  transition: all 0.15s ease-in-out;
  border-radius: 0.25rem;
}

.button-push_button:before {
  content: '';
  position: absolute;
  border-radius: 0 0 0.25rem 0.25rem;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
}

.button-push_button-small:before {
  height: 4px;
}

.button-push_button-small {
  padding: 8px;
}

.button-push_button-large {
  padding: 0.9rem 1.5rem 1rem 1.5rem;
  font-size: 100%;
}

.button-push_button-large:before {
  height: 5px;
}

.button-push_button-primary {
  background-color: #b40089;
  border-color: #530059;
  color: white;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  position: relative;
}

.button-push_button-primary:before {
  background-color: #6e0069;
}

.button-push_button-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(180, 0, 137, 0.25);
}

.button-push_button-primary:hover {
  color: #fff;
  background-color: #9f0076;
}

.button-push_button-primary:disabled {
  color: #fff;
  background-color: #b5b5b5;
  border-color: #7c7c7c;
}

.button-push_button-primary:disabled:before {
  background-color: #646464;
}

.pill-button-glow {
  box-shadow: 0 0 10px 0 white;
}

.button-push_button-primary.button-drop_down-left {
  border-right: 1px solid #6e0069;
}

.button-push_button-primary.button-drop_down-left:disabled {
  border-right: 1px solid #646464;
}

.button-drop_down-right:before {
  border-bottom-left-radius: 0;
}

.button-drop_down-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  white-space: nowrap;
}

.button-drop_down-left:before {
  border-bottom-right-radius: 0;
}

.cart-button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  position: relative;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.table-cell-button {
  flex: 1;
  vertical-align: middle;
  margin: 0;
}

.table-button-cell-header {
  text-align: center;
}

.table-cell-button {
  opacity: 0.75;
}

.table-cell-button:disabled {
  opacity: 0.25;
}

.table-cell-button:hover:not(:disabled) {
  opacity: 1;
}

.expand-collapse-button {
  width: 100%;
  height: 2em;
}

.react-joyride__tooltip button:disabled {
  background-color: transparent !important;
  color: #333333 !important;
}

.button-wrapper {
  display: flex;
  gap: 8px;
  margin: 0 8px;
}

.expand-collapse-button {
  width: 100%;
  height: 2em;
}

@media (max-width: 590px) {
  .button-push_button_label {
    display: none;
  }

  .button-push_button-small {
    font-size: 1rem;
  }
}

@media (min-width: 591px) {
}

@media (max-width: 768px) {
  .reveal-search-button {
    order: 2;
  }
}

@media (min-width: 769px) {
  .subscribe_button {
    display: flex;
  }

  .reveal-search-button {
    display: none;
  }
}

@media (max-width: 1279px) {
}

@media (min-width: 1280px) {
}
