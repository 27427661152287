.waveform_container {
  position: relative;
  margin: 1px;
}

.waveform {
  height: 100%;
  position: absolute;
}

.waveform-background {
  width: 100%;
  opacity: 0.4;
}

.waveform_clip-edge-overlay {
  height: 100%;
  background: black;
  opacity: 0.3;
  position: absolute;
}

.waveform_clip-edge-overlay-skip {
  height: 100%;
  background: #555;
  opacity: 0.3;
  position: absolute;
}

.waveform-position {
  background: linear-gradient(to right, #5996e4 0%, #b69afd 100%);
  width: 100%;
  mask-size: 100% 100%;
}

.waveform_container {
  height: 100%;
  min-height: 80px;
}

.mobile .volume-slider {
  display: none;
}

.preview {
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.preview_actions_title {
  font-weight: 300;
  font-size: 75%;
}

.preview_title {
  font-size: 100%;
  font-weight: 400;
}

.preview_label {
}

.preview_detail {
  font-weight: 300;
}

.preview_details_wrapper {
  flex: 1;
  padding: 1rem 0.5rem;
  display: flex;
  gap: 8px;
  flex-direction: column;
  min-height: 93px;
}

.preview_details_wrapper::-webkit-scrollbar {
  display: none;
}

.preview_actions_wrapper {
  display: flex;
  gap: 8px;
  flex: 1;
  justify-content: flex-end;
}

.preview-icons-container {
  border-radius: 2px;
  display: flex;
  gap: 4px;
}

.waveform_container {
  background-color: black;
}

.button-playback {
  color: white;
  opacity: 0.5;
  width: 3ch;
}

.button-playback:hover {
  opacity: 1;
}

.player-collection-wrapper {
  background-color: #131313;
  display: flex;
  flex-direction: column;
}

.preview_links_container {
  display: flex;
  gap: 16px;
  flex: 1;
}

.available_on_list,
.search_from_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.preview-samples_container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 4px;
  background: #000c !important;
  border-radius: 8px;
  margin: 4px;
  align-items: center;
}

.sample-type-select {
  font-weight: 300;
  border-radius: 2px;
  padding: 2px 4px;
}

.sample-type-select--selected {
  background-color: #b40089;
}

.preview-wrapper {
  height: 100%;
}

@media (max-width: 590px) {
  .button-playback {
    font-size: 150%;
    padding: 8px;
  }

  .preview-action_button .button-push_button_icon {
    display: block;
  }

  .preview_title {
    width: 100%;
  }

  .preview_links_container {
    display: none;
  }

  .preview {
    flex-direction: column;
  }
}

@media (min-width: 591px) {
  .button-playback {
    padding: 5px 10px;
  }

  .button-playback {
    padding: 5px 10px;
  }
}

@media (max-width: 768px) {
  .preview-icons-container {
    font-size: 100%;
  }

  .player-collection-wrapper {
    display: block;
  }

  .preview-samples_container {
    padding: 4px 8px;
  }

  .add_to_cart_popup {
    right: 0;
    left: auto;
  }

  .preview_details_wrapper {
    gap: 16px;
    max-height: 160px;
    overflow-y: auto;
    flex-direction: column;
  }

  .preview_links_wrapper {
    display: none;
  }

  .search_from_list {
    flex-direction: row;
  }

  .preview_links_container {
    flex-direction: row;
  }

  .preview_title {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .preview-icons-container {
    font-size: 75%;
  }

  .player-collection-wrapper {
    display: flex;
  }

  .add_to_cart_popup {
    left: 0;
    right: auto;
  }
  .preview-samples_container {
    padding: 8px;
  }

  .preview_details_wrapper {
    gap: 16px;
  }

  .preview_links_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .search_from_list {
    flex-direction: row;
  }

  .preview_links_container {
    flex-direction: row;
  }
}

@media (max-width: 1028px) {
  .preview-share_caret {
    display: none;
  }

  .preview-pill_link .pill-link-text {
    display: none;
  }
}
