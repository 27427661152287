.page-container {
  flex: 1;
  max-width: 1440px; /* Ensure this is in sync with top_bar_contents */
  background-color: var(--page-container-background-color);
  box-sizing: border-box;
}

.page-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 590px) {
  .page-container {
    margin: 0;
  }
}
@media (min-width: 769px) {
  .page-container {
    margin: 0 1rem;
  }
}

@media (min-width: 1480px) {
  .page-container {
    width: 100%;
    margin: 0 auto;
  }
}
